import React, { PureComponent } from 'react'
import { css } from '@emotion/react'

import { graphql } from 'gatsby'

import * as Organisms from '../components/organisms'
import * as Libs from 'libs'
class Page extends PureComponent {
  //--------------------------------------
  // Styles
  //--------------------------------------
  sectionCss = () =>
    css`
      ${Libs.Styles.Mixin.containerCss()}
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  constructor (props) {
    super(props)
    console.log('imgLoadedList --- Page -  Start')

    this.init = true
    //Transition.InitTransition.InitTransition(Common.Config.getDispatcher)
  }

  componentWillMount () {}
  componentDidMount () {
    if (this.init) this.init = false
    Libs.Common.Config.excuteTransition()

    return false
  }
  componentWillUnmount () {
    console.log('imgLoadedList --- Page -  Unmount')
  }

  getElement (page) {
    if (page.slug === 'index') {
      console.log('imgLoadedList --- Page - getElement  -------')

      return (
        <Organisms.IndexPage
          content={page.content}
          feature={page.feature}
          data-type={'page'}
          className={'project-container'}
        />
      )
    } else if (page.slug === 'creation') {
      return (
        <Organisms.CreationPage
          lang={page.node_locale}
          content={page.content}
          data-type={'page'}
          className={'project-container'}
        />
      )
    } else if (page.slug === 'about') {
      return (
        <Organisms.AboutPage body={page.body} className={'project-container'} />
      )
    }
  }
  render () {
    if (!this.init) return
    console.log('imgLoadedList --- Page - Render  -------')
    const { data } = this.props
    const page = data.contentfulPages
    const element = this.getElement(page)
    return (
      <>
        <Libs.Common.SEO.SEO
          title={page.name !== 'Index' ? page.name : null}
          description={page.description ? page.description.description : null}
          lang={page.node_locale}
          link={page.slug}
        />
        {element}
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulPages(id: { eq: $id }) {
      node_locale
      slug
      name
      description {
        description
      }
      content {
        raw
        references {
          ... on ContentfulElementGrid {
            contentful_id
            __typename
            ...ElementGrid
          }
        }
      }
      feature {
        node_locale
        slug
        title
        date
        name
        category {
          slug
          name
        }

        thumbnail {
          description
          gatsbyImageData(width: 1800, quality: 70)
          file {
            url
          }
        }
        tags {
          name
          slug
          group
        }
      }
    }
  }
`
